
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import ProjectForm from '@/components/ProjectForm.vue';
import { ProjectModel } from '@/models/project-model';
import ProjectsService from '@/services/projects-service';
import { setFocus } from '@/services/dom-tools';

export default defineComponent({
  name: 'Projects',
  components: {
    ListCard,
    ProjectForm,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
      () => setFocus('name'),
      () => this.$router.push({ name: "Signin" })
    );
  },

  data: () => ({
    item: ProjectsService.createNewProject(),
    addMode: true,
  }),

  computed: {
    loading(): boolean { return this.store.state.loadingData; },
    list(): ListCardItem[] { return this.store.getters.projectsList; },
  },

  methods: {
    getProjectById(id: number): ProjectModel {
      return this.store.getters.getProjectById(id);
    },

    onItemClicked(item: ListCardItem) {
      this.$router.push({ name: 'ProjectDetails', params: { id: item.id }});
    },

    onAddClicked() {
      this.addMode = true;
      this.item = ProjectsService.createNewProject();
      setFocus('name');
    },

    onEditClicked(item: ListCardItem) {
      this.addMode = false;
      this.item = this.getProjectById(+item.id);
      setFocus('name');
    },

    onSaveDone(item: ProjectModel) {
      if (this.addMode)
        this.$router.push({ name: 'ProjectDetails', params: { id: item.id } });
      else
        this.item = item;
    },

    onDeleteDone() {
      this.onAddClicked();
    },
  },
});
