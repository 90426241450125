<template>
  <div>
    <MessageBox
      id="confirmDeleteProject"
      buttons="DeleteCancel"
      title="Confirm deletion"
      message="Are you sure you want to delete the project and all its related data?"
      @validate="onDeleteValidate" />

    <div class="card">
      <div class="card-header">
        <span v-if="editMode">Edit project</span>
        <span v-else>Add project</span>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <label for="name">Project name</label>
            <input v-model.trim="name" type="text" class="form-control" id="name" placeholder="Enter project name">
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea v-model.trim="description" class="form-control" id="description" rows="3"></textarea>
          </div>

          <HttpExtendedResultAlert v-if="error" v-bind:result="error" />

          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary" @click.prevent="onSave()" v-bind:disabled="saveDisabled">
              <font-awesome-icon v-if="loading" icon="spinner" spin />
              Save
            </button>
            <button v-if="editMode" type="button" class="btn btn-danger" @click.prevent="onDelete()" v-bind:disabled="loading">Delete</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ProjectModel } from '@/models/project-model';
import MessageBox from '@/components/MessageBox.vue';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'ProjectForm',
  emits: ['savedone', 'deletedone'],
  components: {
    MessageBox,
    HttpExtendedResultAlert,
  },
  props: {
    item: {
      type: ProjectModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    name: '',
    description: '' as string | undefined,

    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  watch: {
    item(newItem: ProjectModel) {
      this.name = newItem.name;
      this.description = newItem.description;
      this.error = undefined;
    },
  },

  computed: {
    editMode(): boolean { return this.item.id > 0; },
    saveDisabled(): boolean { return this.loading || (this.item.name == this.name && this.item.description == this.description); },
  },

  methods: {
    onSave() {
      this.loading = true;
      this.error = undefined;
      const payload = new ProjectModel(this.item.id, this.name, this.description);

      if (this.editMode)
        this.updateProject(payload);
      else
        this.addProject(payload);
    },

    onDelete() {
      clickElement('confirmDeleteProjectShowButton');
    },

    async onDeleteValidate() {
      this.loading = true;
      this.error = undefined;

      this.store.dispatch(ActionTypes.DELETE_PROJECT, this.item.id).then(
        status => this.parseDeleteStatus(status),
        error => this.parseResponse(undefined, error));
    },

    addProject(payload: ProjectModel) {
      this.store.dispatch(ActionTypes.ADD_PROJECT, payload).then(
        project => this.parseResponse(project, undefined),
        error => this.parseResponse(undefined, error));
    },

    updateProject(payload: ProjectModel) {
      this.store.dispatch(ActionTypes.UPDATE_PROJECT, payload).then(
        project => this.parseResponse(project, undefined),
        error => this.parseResponse(undefined, error));
    },

    parseResponse(project?: ProjectModel, error?: HttpExtendedResult) {
      this.loading = false;
      this.error = error;

      if (project)
        this.$emit('savedone', project);
    },

    parseDeleteStatus(status: boolean) {
      this.loading = false;

      if (status) {
        this.$emit('deletedone');
      }
      else {
        const result: HttpExtendedResult = { status: 500, title: 'An unknown error occurred!', isCancel: false };
        this.parseResponse(undefined, result);
      }
    },
  },
});
</script>
